<template>
    <div class="container">
        <div class="container-box">
            <div class="container-box-item" style="margin-left: 0">
                <div class="title">联系我们</div>
                <div class="tell-us-box">
                    <div class="sales-hotline">
                        <div class="tell-us-title"><span>销售热线</span></div>
                        <div class="tell-us-str"><span>023-966500</span></div>
                    </div>
                    <div class="consultation-hotline">
                        <div class="tell-us-title">技术咨询热线</div>
                        <div class="tell-us-str">186 2316 1657</div>
                    </div>
                </div>
                <div class="adress address-class">公司地址</div>
                <div class="adress-container address-class">重庆市渝北区龙塔街道星辰路78号浩博星辰办公楼1幢1-商业</div>
            </div>
            <div class="container-box-item">
                <div class="title">产品与服务</div>
                <div class="products-services-list">
                    <div class="products-services-item" v-for="item in productsServicesList" :key="item" @click="goTo(item.link)">
                        <span>{{ item.title }}</span>
                    </div>
                </div>
            </div>
            <div class="container-box-item">
                <div class="title">解决方案</div>
                <div class="products-services-list">
                    <div class="products-services-item" v-for="item in solutionDatList" :key="item"  @click="goTo('/solutionView')">
                        <div><span>{{ item }}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-box">
          <a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration:none;color: #333;">
            版权所有：重庆帮企科技集团有限公司&copy;  {{new Date().getFullYear()}} 渝ICP备18009567号-8
          </a>
        </div>

    </div>
</template>
<script>

export default {
    name: "newCloudeFooter",
    data() {
        return {
            productsServicesList: [
              {title:"ERP",link:'/ERPsystem',},
              {title:"数字应用",link:'/digitalApplications',},
              {title:"数字办公",link:'/digitalOffice',},
              {title:"CRM",link:'/CRMsystem',},
              {title:"财务管理",link:'/finance',},
              {title:"SCM",link:'/SCMsystem',},
              {title:"KMS",link:'/kmsKnowledge',},
              {title:"WMS",link:'/warehouse',},
              {title:"MES",link:"/factory"},
              {title: '更多产品', link: '/productsService'}
              // {title:"企业大数据",link:'/enterpriseBigData',},
            ],
            solutionDatList: [
                '企业融资大数据平台解决方案', '企业价值大数据智能分析平台解决方案', ' 智慧政务服务平台建设解决方案',
                '知识产权大数据智能监测平台解决方案', '产业大数据智能监测平台解决方案', ' 中小企业公共服务平台建设解决方案',
                ' 企业成长培育服务平台建设解决方案', '智慧园区建设解决方案', '政策大数据平台建设解决方案', '智慧工厂建设解决方案'
            ]
        }
    },
    computed: {},
    methods: {
      goTo(url){
        this.$router.push(url)
      }
    },
    mounted() {
    }
}
</script>
<style lang="scss" scoped>
.container {
  background-color: rgba(235, 237, 247, 1);
  font-size: 14px;
  font-family: 'SourceHanSansCN';
  padding-top: 40px;

  &-box {
    width: 1200px;
    margin: auto;
    color: #333;
    display: flex;
    justify-content: start;
    height: 70%;

    .container-box-item {
      margin-left: 50px;
      padding-bottom: 40px;
    }

    .title {
      font-size: 18px;
      font-family: 'SourceHanSansCN';
      color: #272A3A;
      font-weight: bold;
    }

    .tell-us-box {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-top: 30px;
      font-weight: 500;

      .tell-us-title {
        font-size: 14px;
        font-family: 'SourceHanSansCN';
        font-weight: 500;
        color: rgba(39, 42, 58, 1);

      }

      .tell-us-str {
        font-size: 14px;
        font-family: 'SourceHanSansCN';
        font-weight: 500;
        color: rgba(25, 53, 222, 1);
      }

      .consultation-hotline {
        margin-left: 50px;
      }
    }
  }

  .adress {
    margin-top: 20px;
  }

  .address-class {
    font-size: 14px;
    font-family: 'SourceHanSansCN';
    font-weight: 500;
    color: #333333;
    line-height: 30px;
  }

  .products-services-list {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-row-gap: 18px;
    grid-column-gap: 40px;
    margin-top: 20px;

    .products-services-item {
      font-size: 14px;
      font-family: 'SourceHanSansCN';
      font-weight: 500;
      color: #333333;
      cursor: pointer;
    }
  }
}
.copyright-box{
    font-size: 12px;
    font-family: 'SourceHanSansCN';
    font-weight: 500;
    color:rgba(39, 42, 58, 1);
    line-height: 60px;
    border-top: 1px solid rgba(206, 211, 235, 1);
    text-align: center;
}
</style>
