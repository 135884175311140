<template>
  <div class="head-box">
    <div class="head-box-left">
      <img src="@/assets/img/帮企数智大脑.svg">
      <div class="head-box-left-title">帮企服务云</div>
    </div>
    <div class="head-box-right">
      <div class="head-box-right-title"
           :style="{color:activeTitle=='/home'?'rgba(25, 53, 222, 1)':'rgba(51, 51, 51, 1)'}"
           @click="linkTo('/home')">首页
      </div>
      <div class="head-box-right-title"
           :style="{color:activeTitle=='/productsService' || activeTitle=='/caseDetails' ?'rgba(25, 53, 222, 1)':'rgba(51, 51, 51, 1)'}"
           @click="linkTo('/productsService')">产品与服务
      </div>
      <div class="head-box-right-title"
           :style="{color:activeTitle=='/solution' || activeTitle=='/solutionDetails' ?'rgba(25, 53, 222, 1)':'rgba(51, 51, 51, 1)'}"
           @click="linkTo('/solution')">解决方案
      </div>
      <div class="head-box-right-title"
           :style="{color:activeTitle=='/aboutUsView'?'rgba(25, 53, 222, 1)':'rgba(51, 51, 51, 1)'}"
           @click="linkTo('/aboutUsView')">关于我们
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "newCloudeHeader",
  data() {
    return {
      menuShow: false,
      isLogin: false,
      abridge: ''
    }
  },
  computed: {
    activeTitle: {
      get() {
        return this.$route.path
      }
    }
  },
  methods: {
    go(row) {
      if (row.path) {
        this.$router.push(row.path)
      } else if (row.title == '企业登云') {
        window.open(row.url)
      }
    },
    linkTo(url) {
      this.$router.push(url)
    }
  }
}
</script>
<style scoped lang="scss">
$defaultColor: #1935DE;
.head-box {
  height: 80px;
  background-color: #fff;
  position: sticky;
  top: 0;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1001;

  &-left {
    display: flex;
    align-items: center;

    &-title {
      font-size: 20px;
      color: #333;
      font-weight: bold;
      margin-left: 15px;
    }
  }

  &-right {
    display: flex;
    align-items: center;
    position: relative;

    &-title {
      font-size: 16px;
      color: #333;
      cursor: pointer;
      margin: 0 40px;
    }

    &-login {
      background-color: $defaultColor;
      color: #fff;
      padding: 12px 28px;
      line-height: normal;
      border-radius: 40px;
      font-size: 16px;
      cursor: pointer;
    }

    &-call {
      border: 1px solid $defaultColor;
      color: $defaultColor;
      padding: 12px 20px;
      line-height: normal;
      border-radius: 40px;
      font-size: 16px;
      margin-left: 80px;
    }

    .head-menu {
      position: absolute;
      background-color: #fff;
      left: 0;
      top: calc(100% + 20px);
      padding: 20px;
      box-sizing: border-box;
      max-width: 380px;

      &-title {
        font-size: 18px;
        padding-bottom: 20px;
        border-bottom: 1px solid #eee;
      }

      &-arr {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &-cell {
          margin-top: 20px;
          cursor: pointer;
          width: 110px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
